import { Component, OnInit, Optional, Inject } from '@angular/core';
import {
  NgForm,
  Form,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-edit-volunteer',
  templateUrl: './edit-volunteer.component.html',
  styleUrls: ['./edit-volunteer.component.scss'],
})
export class EditVolunteerComponent implements OnInit {
  volunteerFormGroup: FormGroup;
  volunteerId: string;
  dialogTitle: string;
  toggle: boolean;
  volunteersCollection: AngularFirestoreCollection;
  constructor(
    private fb: FormBuilder,
    private _location: Location,
    public dialogRef: MatDialogRef<EditVolunteerComponent>,
    private db: AngularFirestore,
    private afAuth: AngularFireAuth,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    console.log('this.data.volunteer', this.data.volunteer);
    console.log('this.data', this.data);
    if (this.data.volunteer) {
      this.dialogTitle = 'Deelnemer bewerken';
    } else {
      this.dialogTitle = 'Deelnemer toevoegen';
    }
    if (!this.volunteerId) {
      this.volunteerId = this.db.createId();
    }
    this.volunteersCollection = this.data.volunteersCollection;
    console.log('this.volunteersCollection', this.volunteersCollection);
    this.volunteerFormGroup = this.fb.group({
      name: ['', Validators.required],
      fullName: [],
      group: [],
      comment: [],
      hasGivenPermission: [],
    });
    if (this.data.volunteer) {
      console.log('data.tag', this.data.volunteer);
      this.volunteerId = this.data.volunteer.id;
      this.volunteerFormGroup.patchValue(this.data.volunteer);
    }
  }
  cancel() {
    // console.log('itemForm', this.itemForm);
    // console.log('itemForm', this.itemForm.dirty);
    if (this.volunteerFormGroup.dirty) {
      if (confirm('Weet je zeker dat je wil annuleren zonder op te slaan?')) {
        console.log('Implement delete functionality here');
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  async onSubmit() {
    console.log('submit triggered');
    console.log('itemForm', this.volunteerFormGroup);
    try {
      await this.volunteersCollection
        .doc(this.volunteerId)
        .set(this.volunteerFormGroup.value, { merge: true });

      if (this.volunteerFormGroup.value.hasGivenPermission && !this.data) {
        await this.db.doc(`users/${this.volunteerId}`).set(
          {
            firstName: this.volunteerFormGroup.value.name,
            infoScheduleActive: true,
            infoUserActive: true,
            phone: this.data.volunteer.phone,
          },
          {
            merge: true,
          }
        );
      }

      // await this.db.collection(`users/${}`)
      // this.afAuth.auth.cre
      // add user to
      this.dialogRef.close();
    } catch (err) {
      console.error(err);
    }
  }
}
