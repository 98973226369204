import { NgModule } from '@angular/core';
import { Routes, Router, RouterModule } from '@angular/router';
import {
  AngularFireAuthGuard,
  hasCustomClaim,
  redirectUnauthorizedTo,
  redirectLoggedInTo
} from '@angular/fire/auth-guard';
// import { SelectEntityComponent } from './select-entity/select-entity.component';
import { LoginComponent } from './auth/login/login.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { TasksComponent } from './tasks/tasks.component';
import { ProfileComponent } from './profile/profile.component';
import { VolunteersComponent } from './volunteers/volunteers.component';

import { AppComponent } from './app.component';
import { SelectEntityComponent } from './select-entity/select-entity.component';
import { environment } from 'src/environments/environment';
import { TaskDetailsComponent } from './tasks/task-details/task-details.component';
import { EditTaskComponent } from './tasks/edit-task/edit-task.component';
import { TagsComponent } from './tags/tags.component';
import { SettingsComponent } from './settings/settings.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToTasks = () => redirectLoggedInTo(['tasks']);
// const goToTaskDetail = (next) => hasCustomClaim(`task-${next.params.id}`);

// tslint:disable-next-line: prefer-const
let routes: Routes = [];
const allRoutes = {
  login: {
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToTasks },
  },
  registration: {
    path: 'registration',
    component: RegistrationComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToTasks },
  },
  profile: {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  selectEntity: {
    path: 'select-entity',
    component: SelectEntityComponent
  },
  tasks: {
    path: 'tasks',
    component: TasksComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  volunteers: {
    path: 'volunteers',
    component: VolunteersComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  detail: {
    path: 'task-detail/:orgId/:id',
    component: TaskDetailsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  createTask: {
    path: 'create-task/:orgId',
    component: EditTaskComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  editTask: {
    path: 'edit-task/:orgId/:id',
    component: EditTaskComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  tags: {
    path: 'tags',
    component: TagsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  settings: {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
};
console.log('allroutes', allRoutes);
// environment.routes.forEach(route => {
//   routes.push(allRoutes[route]);
// });
// routes.push({
//   path: '**',
//   redirectTo: 'profile',
//   canActivate: [AngularFireAuthGuard],
//   data: { authGuardPipe: redirectUnauthorizedToLogin },
// });

// console.log('routes', routes);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(router: Router) {
    const config = router.config;
    environment.routes.forEach(route => {
      config.push(allRoutes[route]);
    });
    config.push({
      path: '**',
      redirectTo: 'tasks',
      canActivate: [AngularFireAuthGuard],
      data: { authGuardPipe: redirectUnauthorizedToLogin },
    });
    router.resetConfig(config);
    console.log('router3', router);
  }
}
