import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Task } from '../../edit-task.component';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

export interface QuickPickOption {
  type: 'divider' | 'choose-date' | 'quick-pick';
  description?: string;
  date?: Date;
  selected?: boolean;
  icon?: string;
}
@Component({
  selector: 'app-schedule-task',
  templateUrl: './schedule-task.component.html',
  styleUrls: ['./schedule-task.component.scss'],
})
export class ScheduleTaskComponent implements OnInit {
  step: 'quick-pick' | 'choose-date' = 'quick-pick';
  quickPickOptions: QuickPickOption[] = [];
  selectedIndex = 0;
  currentDate = new Date();
  dateFirstMorning = new Date();
  scheduleForm: FormGroup;
  @HostListener('document:keydown', ['$event'])
  keydown(e: KeyboardEvent) {
    if (e.key === 'ArrowDown' || e.key === 'Tab') {
      this.select('down');
    } else if (e.key === 'ArrowUp') {
      this.select('up');
    } else if (e.key === 'Enter' || e.key === ' ') {
      this.selectDate(this.quickPickOptions[this.selectedIndex]);
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { task: Task },
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ScheduleTaskComponent>
  ) {
    this.dateFirstMorning.setDate(this.currentDate.getDate() + 1);
    this.dateFirstMorning.setHours(7, 0, 0, 0);
    this.quickPickOptions.push({
      type: 'quick-pick',
      description: 'Verstuur vanaf nu',
      date: this.currentDate,
      selected: true,
    });
    this.quickPickOptions.push({
      type: 'quick-pick',
      description: 'Verstuur morgen vanaf',
      date: this.dateFirstMorning,
    });
    this.quickPickOptions.push({
      type: 'divider',
    });
    this.quickPickOptions.push({
      type: 'choose-date',
      description: 'Kies datum en tijd',
      icon: 'date_range',
    });
  }

  select(direction: 'down' | 'up') {
    if (this.quickPickOptions.length > 0) {
      const highestSelectedIndex = this.quickPickOptions.length - 1;
      const currentlySelected = this.quickPickOptions[this.selectedIndex];
      // make newselected default the current one so that it will be kept selected if no new one should be selected
      let newSelected = currentlySelected;
      if (direction === 'down' && this.selectedIndex !== highestSelectedIndex) {
        this.selectedIndex++;
        newSelected = this.quickPickOptions[this.selectedIndex];
      }
      if (direction === 'up' && this.selectedIndex !== 0) {
        this.selectedIndex--;
        newSelected = this.quickPickOptions[this.selectedIndex];
      }
      currentlySelected.selected = false;
      newSelected.selected = true;
      if (newSelected.type === 'divider') {
        // if divider would be selected skip to next one
        this.select(direction);
      }
    }
  }

  ngOnInit() {
    this.scheduleForm = this.fb.group({
      sendStartDate: [new Date()],
      sendStartTime: ['07:00'],
      sendEndDate: [],
      sendEndValue: [1, Validators.required],
      sendEndType: ['hours', Validators.required],
    });
    console.log(this.data);
    if (this.data.task && this.data.task.sendType === 'automatic') {
      const patchObj = {
        sendEndValue: this.data.task.sendEndValue,
        sendEndType: this.data.task.sendEndType,
      } as any;
      const sendStart = this.data.task.sendStart.toDate();
      patchObj.sendStartDate = sendStart;
      patchObj.sendStartTime = `${this.createValidTimeString(
        sendStart.getHours()
      )}:${this.createValidTimeString(sendStart.getMinutes())}`;
      console;
      // console.log('patchObj', patchObj);
      this.scheduleForm.patchValue(patchObj);
      this.step = 'choose-date';
    }
  }

  selectDate(option: QuickPickOption) {
    if (option.type === 'quick-pick') {
      const date = option.date;
      this.scheduleForm.controls.sendStartDate.setValue(date);
      this.scheduleForm.controls.sendStartTime.setValue(
        `${this.createValidTimeString(
          date.getHours()
        )}:${this.createValidTimeString(date.getMinutes())}`
      );
      this.submit();
    } else if (option.type === 'choose-date') {
      this.step = 'choose-date';
    }
  }
  createValidTimeString(val) {
    let timeString = val.toString();
    if (timeString.length === 1) {
      timeString = '0' + timeString;
    }
    return timeString;
  }
  getSendDate(date, sendValue, sendType) {
    let timeOfString;
    // console.log('date', date);
    // console.log('sendType', sendType);
    switch (sendType) {
      case 'hours': {
        timeOfString = 60 * 60 * 1000 * sendValue;
        break;
      }
      case 'days': {
        timeOfString = 24 * 60 * 60 * 1000 * sendValue;
        break;
      }
      case 'weeks': {
        timeOfString = 7 * 24 * 60 * 60 * 1000 * sendValue;
        break;
      }
    }
    // console.log('timeOfString', timeOfString);
    const sendDate = new Date(date.setTime(date.getTime() - timeOfString));
    // console.log('sendDate', sendDate);
    // if (sendType ==)
    return sendDate;
  }
  submit() {
    if (!this.scheduleForm.valid) {
      return;
    }
    const hourString = this.scheduleForm.value.sendStartTime.substring(0, 2);
    const minuteString = this.scheduleForm.value.sendStartTime.substring(3, 5);
    const hour = Number(hourString);
    const minute = Number(minuteString);
    const startDateTime = this.scheduleForm.value.sendStartDate.setHours(
      hour,
      minute,
      0,
      0
    );
    this.scheduleForm.controls.sendStartDate.setValue(new Date(startDateTime));
    this.scheduleForm.controls.sendEndDate.setValue(
      this.getSendDate(
        this.data.task.date,
        this.scheduleForm.value.sendEndValue,
        this.scheduleForm.value.sendEndType
      )
    );
    console.log('scheduleForm', this.scheduleForm.value);
    this.dialogRef.close(this.scheduleForm.value);
  }
}
