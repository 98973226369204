import { Component, OnInit, Optional, Inject } from '@angular/core';
import { NgForm, Form, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';


@Component({
  selector: 'app-edit-tag',
  templateUrl: './edit-tag.component.html',
  styleUrls: ['./edit-tag.component.scss']
})
export class EditTagComponent implements OnInit {
  tagControl: FormGroup;
  tagId: string;
  dialogTitle: string;
  color: string;
  toggle: boolean;
  tagsCollection: AngularFirestoreCollection;
  mentionItems: string[] = ['Naam', 'Organisatie'];

  constructor(
    private fb: FormBuilder, private _location: Location,
    public dialogRef: MatDialogRef<EditTagComponent>, private db: AngularFirestore,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data.tag) {
      this.dialogTitle = 'Tag bewerken';
    } else {
      this.dialogTitle = 'Tag toevoegen';
    }
    if (!this.tagId) {
      this.tagId = this.db.createId();
      this.color = '#000000';
    }
    this.tagsCollection = this.data.tagsCollection;
    console.log('this.tagsCollection', this.tagsCollection);
    this.tagControl = this.fb.group({
      name: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      tagQuestion: ['', Validators.required],
      color: [this.color, Validators.required],
    });
    if (this.data.tag) {
      console.log('data.tag', this.data.tag);
      this.tagId = this.data.tag.id;
      this.color = this.data.tag.color;
      this.tagControl.patchValue(this.data.tag);
    }
  }
  cancel() {
    // console.log('itemForm', this.itemForm);
    // console.log('itemForm', this.itemForm.dirty);
    if (this.tagControl.dirty) {
      if (confirm('Weet je zeker dat je wil annuleren zonder op te slaan?')) {
        console.log('Implement delete functionality here');
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  async onSubmit() {
    console.log('submit triggered');
    this.tagControl.value.color = this.color;
    console.log('itemForm', this.tagControl);
    try {
      await this.tagsCollection.doc(this.tagId).set(this.tagControl.value, { merge: true });
      this.dialogRef.close();
    } catch (err) {
      console.error(err);
    }
  }

}
