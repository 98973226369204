export const environment = {
  env: 'vriend',
  name: 'Hallo Vriend',
  production: false,
  firebase: {
    apiKey: 'AIzaSyCI1ZtYEz3myRupKXwkgZ_f-pLthoC752k',
    authDomain: 'hallo-bot.firebaseapp.com',
    databaseURL: 'https://hallo-bot.firebaseio.com',
    projectId: 'hallo-bot',
    storageBucket: 'hallo-bot.appspot.com',
    messagingSenderId: '82103981851',
    appId: '1:82103981851:web:8cc527dbf1280df8b025be',
  },
  functionsUrl: 'https://us-central1-hallo-bot.cloudfunctions.net',
  loginMethod: {
    phone: false,
    email: true,
  },
  background: {
    profile:
      'https://firebasestorage.googleapis.com/v0/b/helperapp-af994.appspot.com/o/Background%2FHelperApp_ProfielBanner_130120-01.png?alt=media&token=cb1797ba-119c-465f-802c-be025d98b6c4',
  },
  login: {
    video:
      'https://firebasestorage.googleapis.com/v0/b/hallo-bot.appspot.com/o/HalloVriendShadowBanner2.mp4?alt=media&token=06bfe475-f801-4dfc-a1a2-2acc428ca3c2',
    image:
      'https://firebasestorage.googleapis.com/v0/b/hallo-bot.appspot.com/o/HalloVriend_Logo_500x500-02.svg?alt=media&token=e8b5ea3e-94e5-4899-bd6f-b94ace15a4b6',
  },
  phone: {
    nl: '+316',
    us: '+1',
  },
  routes: [
    'login',
    'registration',
    'tasks',
    'detail',
    'createTask',
    'editTask',
    'volunteers',
    'tags',
    'settings',
  ],
};
