import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Timestamp } from '@firebase/firestore-types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { FirstTimeDialogComponent } from '../profile/dialogs/first-time-dialog/first-time-dialog.component';

export interface User {
  firstName: string;
  phone: string;
  postal: number;
  address: string;
  ICEnumber: number;
  birthdate: Timestamp;
  contacts: string;
  reminder: string;
  id: string;
}

export interface Reminders {
  id: string;
  name: string;
  time: number;
  order: number;
}
export interface RemindersId extends Reminders {
  id: string;
}

export interface Schedulings {
  name: string;
  repeat: boolean;
  startTime: Timestamp;
}
export interface SchedulingsId extends Schedulings {
  id: string;
}

export interface Tags {
  ref: string;
  tags: {
    preference: boolean;
    ref: string;
  };
}

export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  // USER RELATED
  userDoc: AngularFirestoreDocument<User>;
  userTags: AngularFirestoreDocument<Tags>;
  user: Observable<User>;

  userId: string; // @Input
  showChipEdit: boolean;
  community: Observable<Tags>;

  remindersCollection: AngularFirestoreCollection<Reminders>;
  reminders: Observable<RemindersId[]>;

  schedulingsCollection: AngularFirestoreCollection<Schedulings>;
  schedulings: Observable<SchedulingsId[]>;
  // QR code
  qrCode: string = null;
  mobileDevice: boolean;

  // TAGS
  // tagControl = new FormControl();
  // tags: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  // yesTags:string[] = ['testing','miense'];
  // noTags:string[] = [];

  // DIALOG
  // animal: string;
  // name: string;

  name: string = environment.name;
  constructor(
    private route: Router,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    public dialog: MatDialog
  ) {
    // console.log('',environment.background.profile);
    this.determineDevice();
    this.qrCode = 'Your QR code data string';
    this.user = new Observable();
    this.reminders = new Observable();
    this.schedulings = new Observable();
    // this.userId = new Observable();
    this.afAuth.user.subscribe((user) => {
      if (user) {
        this.userId = user.uid;
        console.log('userlogin =>', user);
        this.userDoc = afs.doc<User>('users/' + user.uid);
        this.user = this.userDoc.valueChanges().pipe(
          map((user) => {
            console.log('User =>', user);
            return user;
          })
        );
        // this.remindersCollection = afs.collection<Reminders>('users/' + this.userId + '/reminders');
        // this.schedulingsCollection = afs.collection<Schedulings>('users/' + this.userId + '/scheduling');
        // this.reminders = this.remindersCollection.snapshotChanges().pipe(map(actions => actions.map(a => {
        //   const data = a.payload.doc.data() as Reminders;
        //   const id = a.payload.doc.id;
        //   console.log('reminders = >', id, data);
        //   return { id, ...data};
        // })));
        // this.schedulings = this.schedulingsCollection.snapshotChanges().pipe(map(actions => actions.map(a => {
        //   const data = a.payload.doc.data() as Schedulings;
        //   const id = a.payload.doc.id;
        //   console.log('Schedulings = >', id, data);
        //   return { id, ...data};
        // })));
        console.log('userId =>', this.userId);

        // check if user has the required data for the application
        this.user.subscribe((userData) => {
          console.log('user =>', userData);
          if (userData === undefined) {
            if (this.mobileDevice) {
              const dialogRef = this.dialog.open(FirstTimeDialogComponent, {
                maxWidth: '100vw',
                maxHeight: '100vh',
                height: '100%',
                width: '100%',
                disableClose: true,
                data: { userId: this.userId, userPhone: user.phoneNumber },
              });
              dialogRef.afterClosed().subscribe(() => {
                console.log('The dialog was closed');
                // this.animal = result;
              });
            } else {
              console.log('User document does not exist');
              console.log('open dialog');
              const dialogRef = this.dialog.open(FirstTimeDialogComponent, {
                width: '500px',
                // height: '200px',
                disableClose: true,
                data: { userId: this.userId, userPhone: user.phoneNumber },
              });
              dialogRef.afterClosed().subscribe(() => {
                console.log('The dialog was closed');
                // this.animal = result;
              });
            }
          } else if (
            userData.firstName === undefined ||
            userData.phone === undefined
          ) {
            if (this.mobileDevice) {
              console.log('User document does not exist');
              console.log('open dialog');
              const dialogRef = this.dialog.open(FirstTimeDialogComponent, {
                maxWidth: '100vw',
                maxHeight: '100vh',
                height: '100%',
                width: '100%',
                disableClose: true,
                data: {
                  userId: this.userId,
                  userPhone: userData.phone,
                  userContacts: userData.contacts,
                  userFirstName: userData.firstName,
                },
              });
              dialogRef.afterClosed().subscribe(() => {
                console.log('The dialog was closed');
                // this.animal = result;
              });
            } else {
              console.log('User document does not exist');
              console.log('open dialog');
              const dialogRef = this.dialog.open(FirstTimeDialogComponent, {
                width: '500px',
                // height: '200px',
                disableClose: true,
                data: {
                  userId: this.userId,
                  userPhone: userData.phone,
                  userContacts: userData.contacts,
                  userFirstName: userData.firstName,
                },
              });
              dialogRef.afterClosed().subscribe(() => {
                console.log('The dialog was closed');
                // this.animal = result;
              });
            }
          }
        });
      }
    });
  }

  ngOnInit() {}

  determineDevice() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      console.log('mobile device detected', navigator.userAgent);
      this.mobileDevice = true;
    } else {
      console.log('none mobile device detected');
      this.mobileDevice = false;
    }
    console.log('this.mobileDevice', this.mobileDevice);
  }

  // openDialog(){
  //   console.log('open dialog');
  // }s
}
