import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogData } from '../../../profile/profile.component';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { Timestamp } from '@firebase/firestore-types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, Validators } from '@angular/forms';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-first-time-dialog',
  templateUrl: './first-time-dialog.component.html',
  styleUrls: ['./first-time-dialog.component.scss']
})
export class FirstTimeDialogComponent implements OnInit {
  userId: string;
  userFirstName: string;
  userPhone: string;
  userContacts: string;
  prefix: string = environment.phone.nl;

  mobileDevice: boolean;

  contactPhone: string;
  contactName: string;
  phoneControl: FormControl;

  constructor(public dialogRef: MatDialogRef<FirstTimeDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public afs: AngularFirestore,
    private _snackBar: MatSnackBar,
    private route: Router) {
    this.determineDevice();
    this.phoneControl = new FormControl('+316', [Validators.minLength(12), Validators.maxLength(12)]);
    this.userId = data.userId;
    this.userPhone = data.userPhone;
    this.userContacts = data.userContacts;
    this.userFirstName = data.userFirstName;
    if (!this.contactPhone) {
      this.contactPhone = this.prefix;
    }
  }

  ngOnInit() {
  }

  async saveContact() {
    console.log('user add data', this.userFirstName, this.userPhone);
    const userDoc = this.afs.doc('users/' + this.userId);
    // let reminder = {
    //   time: reminderTime
    // }
    const contact = [{
      name: this.contactName,
      time: 0,
      phone: this.contactPhone
    }];
    await userDoc.set({
      firstName: this.userFirstName,
      phone: this.userPhone,
      contacts: contact
    }), { merge: true };
    this.dialogRef.close();
    this._snackBar.open('Persoonlijke gegevens aangepast', '', {
      duration: 5000,
    });
    this.route.navigate(['/profile']);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  determineDevice() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      console.log('mobile device detected', navigator.userAgent);
      this.mobileDevice = true;
    } else {
      console.log('none mobile device detected');
      this.mobileDevice = false;
    }
    console.log('this.mobileDevice', this.mobileDevice);
  }
}
