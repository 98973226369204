import {
  Component,
  OnInit,
  Injectable
} from '@angular/core';
import {
  Router
} from '@angular/router';
import * as firebase from 'firebase';
import {
  auth
} from 'firebase/app';
import {
  AngularFireAuth
} from '@angular/fire/auth';

import {
  MatSnackBar
} from '@angular/material/snack-bar';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import {
  ErrorStateMatcher
} from '@angular/material/core';

import {
  environment
} from '../../../environments/environment';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {
  video: string = environment.login.video;
  name: string = environment.name;
  prefix: string = environment.phone.nl;
  loginMethodEmail = environment.loginMethod.email;

  email: string;
  password: string;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  matcher = new MyErrorStateMatcher();
  errorLoginMessage: string;
  user: any;
  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private _snackBar: MatSnackBar) {
    // console.log("Login methode => ", this.loginMethod)
  }

  // Login with Email
  mailLogin() {
    this.afAuth.auth.signInWithEmailAndPassword(this.email, this.password)
      .then(() => {
        this.router.navigate(['/tasks'], {});
      })
      .catch(error => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        this.errorLoginMessage = 'De combinatie email en wachtwoord is niet correct';
        this.password = '';
        console.log(errorCode, errorMessage);
        // this._snackBar.open('Email en/of wachtwoord is incorrect ingevuld', 'X', {
        //   duration: 5000,
        // });
        // ...
      });
  }
  async ngOnInit() {

  }

}
