import { Component, OnInit, Inject, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Timestamp } from '@firebase/firestore-types';
import * as firebase from 'firebase/app';
import { FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ReminderComponent } from '../../dialogs/reminder/reminder/reminder.component';
import { ContactsComponent } from '../../dialogs/contacts/contacts/contacts.component';
import { componentFactoryName } from '@angular/compiler';
import { EditProfileComponent } from '../../dialogs/edit-profile/edit-profile.component';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface User {
  firstName: string;
  phone: string;
  postal: number;
  address: string;
  ICEnumber: number;
  birthdate: Timestamp;
  contacts: string;
  reminder: string;
  id: string;
}

export interface Reminders {
  id: string;
  name: string;
  delay: number;
  order: number;
}
export interface RemindersId extends Reminders { id: string; }

export interface Tags {
  ref: string;
  tags: {
    preference: boolean;
    ref: string
  };
}

export interface DialogData {
  animal: string;
  name: string;
}

export interface Tags {
  ref: string;
  tags: {
    preference: boolean;
    ref: string
  };
}

@Component({
  selector: 'app-profile-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss', '../../profile.component.scss']
})
export class AboutComponent implements OnInit {
  userDoc: AngularFirestoreDocument<User>;
  userTags: AngularFirestoreDocument<Tags>;
  user: Observable<User>;

  userId: string; // @Input
  qrCode: string = null;
  showChipEdit = false;
  community: Observable<Tags>;

  mobileDevice: boolean;
  // TAGS
  tagControl = new FormControl();
  tags: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  yesTags: string[] = ['testing', 'miense'];
  noTags: string[] = [];

  remindersCollection: AngularFirestoreCollection<Reminders>;
  reminders: Observable<RemindersId[]>;

  constructor(
    private route: Router, public afAuth: AngularFireAuth, public afs: AngularFirestore, public dialog: MatDialog,
    private _snackBar: MatSnackBar) {
    this.determineDevice();
    this.qrCode = 'Your QR code data string';
    this.user = new Observable();
    this.reminders = new Observable();
    // this.userId = new Observable();
    this.afAuth.user.subscribe(user => {
      if (user) {
        this.userId = user.uid;
        this.userDoc = afs.doc<User>('users/' + user.uid);
        this.remindersCollection = afs.collection<Reminders>('users/' + user.uid + '/reminders');
        this.user = this.userDoc.valueChanges().pipe(map(userData => {
          console.log('User =>', userData);
          return userData;
        }));
        this.user.subscribe(userData => {
          console.log('Check user data => ', userData);
        });
        this.reminders = this.remindersCollection.snapshotChanges().pipe(map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Reminders;
          const id = a.payload.doc.id;
          console.log('reminders = >', id, data);
          return { id, ...data };
        })));
        console.log('userId =>', this.userId);
      } else {
        // not signed in
        this.route.navigate(['/profile']);
      }
    });
  }

  ngOnInit() {
  }

  toggle() {
    this.showChipEdit = true;
  }

  closeChips() {
    console.log('closing chips');
    this.showChipEdit = false;
  }

  // edit reminders
  editReminder(reminderId, reminderName, reminderTime): void {
    if (this.mobileDevice) {
      console.log('open dialog');
      const dialogRef = this.dialog.open(ReminderComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'app-full-screen-dialog',
        data: { userId: this.userId, editReminderId: reminderId, editReminderName: reminderName, editReminderTime: reminderTime }
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    } else {
      console.log('open dialog');
      const dialogRef = this.dialog.open(ReminderComponent, {
        width: '500px',
        // height: '200px',
        data: { userId: this.userId, editReminderId: reminderId, editReminderName: reminderName, editReminderTime: reminderTime }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    }
  }

  deleteReminder() {

  }
  // add, edit and delete contacts
  addContacts(contactName?, contactDelay?, contactPhone?): void {
    if (this.mobileDevice) {
      console.log('open dialog');
      const dialogRef = this.dialog.open(ContactsComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'app-full-screen-dialog',
        data: { userId: this.userId, contact: true, contactName, contactDelay, contactPhone }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    } else {
      console.log('open dialog');
      const dialogRef = this.dialog.open(ContactsComponent, {
        width: '500px',
        // height: '200px',
        data: { userId: this.userId, contact: true, contactName, contactDelay, contactPhone }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    }
  }

  editContacts(contactName, contactDelay, contactPhone): void {
    if (this.mobileDevice) {
      const dialogRef = this.dialog.open(ContactsComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'app-full-screen-dialog',
        data: {
          userId: this.userId, editContact: true, editContactName: contactName,
          editcontactDelay: contactDelay, editContactPhone: contactPhone
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    } else {
      const dialogRef = this.dialog.open(ContactsComponent, {
        width: '500px',
        // height: '200px',
        data: {
          userId: this.userId, editContact: true, editContactName: contactName,
          editcontactDelay: contactDelay, editContactPhone: contactPhone
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    }
  }

  deleteContact(contactName, contactDelay, contactPhone) {
    console.log('lets delete this user', contactName, contactDelay, contactPhone);
    const userDoc = this.afs.doc('users/' + this.userId);
    const contact = {
      name: contactName,
      delay: contactDelay,
      phone: contactPhone
    };
    userDoc.update({
      contacts: firebase.firestore.FieldValue.arrayRemove(contact)
    });
    this._snackBar.open('Contactpersoon verwijderd', '', {
      duration: 5000,
    });
  }

  editProfile(firstName, phone) {
    if (this.mobileDevice) {
      console.log('open dialog');
      const dialogRef = this.dialog.open(EditProfileComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'app-full-screen-dialog',
        data: { userId: this.userId, userName: firstName, userPhone: phone }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    } else {
      console.log('open dialog');
      const dialogRef = this.dialog.open(EditProfileComponent, {
        width: '500px',
        // height: '200px',
        data: { userId: this.userId, userName: firstName, userPhone: phone }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    }
  }

  determineDevice() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      console.log('mobile device detected', navigator.userAgent);
      this.mobileDevice = true;
    } else {
      console.log('none mobile device detected');
      this.mobileDevice = false;
    }
    console.log('this.mobileDevice', this.mobileDevice);
  }
}
