import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
  Renderer2,
} from '@angular/core';
// import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { Router, NavigationEnd, RouterEvent, Event } from '@angular/router';
import { map, filter } from 'rxjs/operators';
// import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Timestamp } from '@firebase/firestore-types';
import { ConstantsService } from './../common/services/constants.service';
import { MatSidenav } from '@angular/material/sidenav';
import { UpgradeDialogComponent } from '../profile/dialogs/upgrade-dialog/upgrade-dialog.component';
import { environment } from '../../environments/environment';
import { MatDialog } from '@angular/material';

export interface User {
  firstName: string;
  phone: string;
  postal: number;
  address: string;
  ICEnumber: number;
  birthdate: Timestamp;
  email: string;
  organisationRef: any;
}

export interface Organisation {
  name: string;
  plan: any;
}
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  @ViewChild('sidenav', { static: false }) sidenav: any;
  @ViewChild('menu', { static: false }) menu: ElementRef;
  @ViewChild('toggleButton', { static: false }) toggleButton: ElementRef;
  userDoc: AngularFirestoreDocument<User>;
  userId: Observable<string>;
  user: Observable<User>;
  organisationDoc: AngularFirestoreDocument<Organisation>;
  $organisation: Observable<Organisation>;
  organisation: Organisation;
  organisationName: string;
  fixed = true;
  userEmail: string;
  backButton: boolean;
  hamburgerMenu: string[] = [
    '/tasks',
    '/volunteers',
    '/dashboard',
    '/settings',
    '/tags',
    '/',
  ];
  constructor(
    private route: Router,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    public constant: ConstantsService,
    private renderer: Renderer2,
    public dialog: MatDialog
  ) {
    // route.events.pipe(
    //   filter(e => e instanceof RouterEvent)
    // ).subscribe((event: Event) => {
    //   // console.log(e.id, e.url);
    //   for (let i = 0; i < this.hamburgerMenu.length; i++) {
    //     // console.log(e.id, e.url);
    //     console.log(this.hamburgerMenu[i]);
    //     if (this.hamburgerMenu[i] === e.url) {
    //       // console.log('needs hamburger');
    //       this.backButton = false;
    //       return;
    //     } else {
    //       // console.log('no hamburger');
    //       this.backButton = true;
    //     }
    //   }
    //   // console.log('this.backButton', this.backButton);
    // });
    this.route.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        console.log('event.url', event.url);
        for (let i = 0; i < this.hamburgerMenu.length; i++) {
          // console.log(e.id, e.url);
          if (this.hamburgerMenu[i] === event.url) {
            // console.log('needs hamburger');
            this.backButton = false;
            return;
          } else {
            // console.log('no hamburger');
            this.backButton = true;
          }
        }
      }
    });
    this.afAuth.user.subscribe((user) => {
      if (user) {
        this.userEmail = user.email;
        this.userDoc = afs.doc<User>('users/' + user.uid);
        this.user = this.userDoc.valueChanges();
        this.user.subscribe((res) => {
          this.organisationDoc = afs.doc<Organisation>(
            res.organisationRef.path
          );
          this.$organisation = this.organisationDoc.valueChanges();
          this.$organisation.subscribe((value) => {
            this.organisation = value;
            this.organisationName = value.name;
          });
        });
        console.log('environment.name', environment);
      }
    });
    if (!this.backButton) {
      this.renderer.listen('window', 'click', (event) => {
        if (!this.toggleButton) {
          return;
        }
        if (
          !this.toggleButton.nativeElement.contains(event.target) &&
          !this.menu.nativeElement.contains(event.target)
        ) {
          this.sidenav.close();
        } else {
          console.log('Hamburger not needed');
        }
      });
    }
  }

  browserBack() {
    window.history.back();
  }

  async logout() {
    console.log('User pressed button');
    await this.afAuth.auth.signOut();
    return this.route.navigate(['/login']);
  }

  ngOnInit() {}
  showUpdate() {
    const dialogRef = this.dialog.open(UpgradeDialogComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }
}
