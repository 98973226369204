import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../../profile/profile.component';
import { FormControl } from '@angular/forms';
import { Observable, Subscriber } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { Timestamp } from '@firebase/firestore-types';
import { observeOn } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface ScheduleItem {
  name: string;
  repeat: object;
  time: Date;
  active: boolean;
  responseRequired: boolean;
}

export interface Minute {
  value: number;
  viewValue: string;
}
export interface Hour {
  value: number;
  viewValue: string;
}

export interface SelectedDays {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.scss']
})
export class SchedulingComponent implements OnInit {
  userId: string;
  scheduleName: string;
  fromControlhour = new FormControl();
  hours: Hour[] = [
    { viewValue: '00', value: 0 },
    { viewValue: '01', value: 1 },
    { viewValue: '02', value: 2 },
    { viewValue: '03', value: 3 },
    { viewValue: '04', value: 4 },
    { viewValue: '05', value: 5 },
    { viewValue: '06', value: 6 },
    { viewValue: '07', value: 7 },
    { viewValue: '08', value: 8 },
    { viewValue: '09', value: 9 },
    { viewValue: '10', value: 10 },
    { viewValue: '11', value: 11 },
    { viewValue: '12', value: 12 },
    { viewValue: '13', value: 13 },
    { viewValue: '14', value: 14 },
    { viewValue: '15', value: 15 },
    { viewValue: '16', value: 16 },
    { viewValue: '17', value: 17 },
    { viewValue: '18', value: 18 },
    { viewValue: '19', value: 19 },
    { viewValue: '20', value: 20 },
    { viewValue: '21', value: 21 },
    { viewValue: '22', value: 22 },
    { viewValue: '23', value: 23 },

  ];
  hour: string[] = [];
  selectedHour: number;

  selectedDays: any;
  daySelect: any;

  itemId: string;
  editItem: boolean;

  fromControlMinute = new FormControl();
  // minutes: string[] = ['00','05','10','15','20','25','30','35','40','45','50','55','60'];
  minutes: Minute[] = [
    { viewValue: '00', value: 0 },
    { viewValue: '05', value: 5 },
    { viewValue: '10', value: 10 },
    { viewValue: '15', value: 15 },
    { viewValue: '20', value: 20 },
    { viewValue: '25', value: 25 },
    { viewValue: '30', value: 30 },
    { viewValue: '35', value: 35 },
    { viewValue: '40', value: 40 },
    { viewValue: '45', value: 45 },
    { viewValue: '50', value: 50 },
    { viewValue: '55', value: 55 },
    // {viewValue: '60 minuten', value: 60},
  ];
  minute: string[] = [];
  selectedMinute: number;

  responseRequired: boolean;

  mobileDevice: boolean;

  constructor(public dialogRef: MatDialogRef<SchedulingComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public afs: AngularFirestore, private _snackBar: MatSnackBar) {
    this.determineDevice();
    this.userId = data.userId;
    if (data.editId) {
      this.editItem = true;
      this.itemId = data.editId;
      this.scheduleName = data.editName;
      this.selectedMinute = data.editTime.getMinutes();
      this.selectedHour = data.editTime.getHours();
      this.daySelect = data.editRepeat;
      this.responseRequired = data.editResponseRequired;
      console.log('time', this.selectedMinute);
      console.log('repeat', this.daySelect);
    } else {
      this.daySelect = [
        { monday: false },
        { tuesday: false },
        { wednesday: false },
        { tuesday: false },
        { friday: false },
        { saturday: false },
        { sunday: false },
      ];
    }
    if (!this.responseRequired) {
      this.responseRequired = true;
    }
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async saveScheduler(chosenDays) {
    this.selectedDays = {};
    const selectedTime = new Date();
    console.log('selected hour and minute', this.selectedHour, this.selectedMinute);
    selectedTime.setFullYear(2020, 0, 5);
    selectedTime.setHours(this.selectedHour, this.selectedMinute, 0, 0);
    console.log('Selected time =>', selectedTime);
    const schedulerCollection = this.afs.collection<ScheduleItem>('users/' + this.userId + '/scheduleItems/');
    const schedulerDoc = this.afs.doc('users/' + this.userId + '/scheduleItems/' + this.itemId);
    console.log('Selected days => ', chosenDays);
    chosenDays.forEach(element => {
      if (element === 'monday') {
        console.log('monday selected');
        this.selectedDays.monday = true;
        // return this.selectedDays;
      }
      if (element === 'tuesday') {
        console.log('tuesday selected');
        this.selectedDays.tuesday = true;
        // return this.selectedDays;
      }
      if (element === 'wednesday') {
        console.log('wednesday selected');
        this.selectedDays.wednesday = true;
        // return this.selectedDays;
      }
      if (element === 'thursday') {
        console.log('thursday selected');
        this.selectedDays.thursday = true;
        // return this.selectedDays;
      }
      if (element === 'friday') {
        console.log('friday selected');
        this.selectedDays.friday = true;
        // return this.selectedDays;
      }
      if (element === 'saturday') {
        console.log('saturday selected');
        this.selectedDays.saturday = true;
        // return this.selectedDays;
      }
      if (element === 'sunday') {
        console.log('sunday selected');
        this.selectedDays.sunday = true;
        // return this.selectedDays;
      }
    });
    // this.selectedDays.subscribe( x => {
    //   console.log('x', x);
    // })
    console.log(this.selectedDays);
    if (this.editItem) {
      await schedulerDoc.update({
        time: selectedTime,
        repeat: this.selectedDays,
        name: this.scheduleName,
        responseRequired: this.responseRequired
      });
      this._snackBar.open('planning aangepast', '', {
        duration: 5000,
      });
    } else {
      await schedulerCollection.add({
        time: selectedTime,
        repeat: this.selectedDays,
        name: this.scheduleName,
        responseRequired: this.responseRequired,
        active: true
      });
      this._snackBar.open('Planning opgeslagen', '', {
        duration: 5000,
      });
    }
    this.dialogRef.close();
    console.log('selectedDays =>', this.selectedDays);
  }

  determineDevice() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      console.log('mobile device detected', navigator.userAgent);
      this.mobileDevice = true;
    } else {
      console.log('none mobile device detected');
      this.mobileDevice = false;
    }
    console.log('this.mobileDevice', this.mobileDevice);
  }
}
