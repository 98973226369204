import { Component, OnInit, Inject, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, empty } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Timestamp } from '@firebase/firestore-types';
import * as firebase from 'firebase/app';
import {FormControl} from '@angular/forms';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { SchedulingComponent } from '../../dialogs/scheduling/scheduling.component';
import { componentFactoryName } from '@angular/compiler';

export interface User {
  firstName: string;
  phone: string;
  postal: number;
  address: string;
  ICEnumber: number;
  birthdate: Timestamp;
  contacts: string;
  reminder: string;
  id: string;
}
export interface Schedules {
  name: string;
  repeat: any;
  time: Timestamp;
  responseRequired: boolean;
  active: boolean;
}
export interface SchedulesId extends Schedules { id: string; }

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-profile-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss', '../../profile.component.scss']
})
export class ScheduleComponent implements OnInit {
  userDoc: AngularFirestoreDocument<User>;
  // userTags: AngularFirestoreDocument<Tags>;
  user: Observable<User>;

  userId: string; // @Input
  schedulesCollection: AngularFirestoreCollection<Schedules>;
  schedules: Observable<SchedulesId[]>;

  // scheduleDoc: AngularFirestoreDocument<Schedulings>;
  mobileDevice: boolean;
  constructor(private route: Router, public afAuth: AngularFireAuth, public afs: AngularFirestore, public dialog: MatDialog) {
    this.determineDevice();
    this.schedules = new Observable();
    this.afAuth.user.subscribe(user => {
      if (user) {
        this.userDoc = afs.doc<User>('users/' + user.uid);
        this.schedulesCollection = afs.collection<Schedules>('users/' + user.uid + '/scheduleItems');
        this.userId = user.uid;
        // this.user = this.userDoc.valueChanges().pipe(map(user => {
        //   console.log('User =>', user);
        //   return user;
        // }));
        this.schedules = this.schedulesCollection.snapshotChanges().pipe(map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Schedules;
          const id = a.payload.doc.id;
          console.log('Schedules = >', id, data);
          return { id, ...data};
        })));
        this.schedules.subscribe(schedule => {
          console.log(schedule);
        });
        console.log(this.schedules);
        console.log('userId =>', this.userId);
      } else {
        // not signed in'
        this.route.navigate(['/profile']);
      }
    });
  }

  ngOnInit() {
  }

  addSchedule(): void {
    if (this.mobileDevice) {
      console.log('open dialog');
      const dialogRef = this.dialog.open(SchedulingComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'app-full-screen-dialog',
        data: {userId: this.userId}
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    } else {
      console.log('open dialog');
      const dialogRef = this.dialog.open(SchedulingComponent, {
        width: '650px',
        // height: '200px',
        data: {userId: this.userId}
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    }
  }

  editSchedule(id, time, name, repeat, responseRequired): void {
    if (this.mobileDevice) {
      console.log('open dialog');
      const dialogRef = this.dialog.open(SchedulingComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'app-full-screen-dialog',
        data: { userId: this.userId, editId: id,
                editTime: time, editName: name, editRepeat: repeat,
                editResponseRequired : responseRequired }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    } else {
      console.log('open dialog');
      const dialogRef = this.dialog.open(SchedulingComponent, {
        width: '650px',
        // height: '200px',
        data: { userId: this.userId, editId: id, editTime: time,
                editName: name, editRepeat: repeat, editResponseRequired : responseRequired }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    }
  }

  deleteSchedule(Id): void {
    console.log('id', Id);
    const scheduleDoc = this.afs.doc<Schedules>('users/' + this.userId + '/scheduleItems/' + Id);
    scheduleDoc.delete();
    console.log('document deleted');
  }

  updateState(state, Id) {
    console.log('change state => ', state);
    const scheduleDoc = this.afs.doc('users/' + this.userId + '/scheduleItems/' + Id);
    scheduleDoc.update({active: state.checked}), {merge: true};
  }

  determineDevice() {
    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
          console.log('mobile device detected', navigator.userAgent);
          this.mobileDevice = true;
     } else {
        console.log('none mobile device detected');
        this.mobileDevice = false;
     }
    console.log('this.mobileDevice', this.mobileDevice);
  }
}
