import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../../profile/profile.component';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { Timestamp } from '@firebase/firestore-types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  userId: string;
  userFirstName: string;
  userPhone: string;
  prefix: string = environment.phone.nl;

  mobileDevice: boolean;
  constructor(public dialogRef: MatDialogRef<EditProfileComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public afs: AngularFirestore,
    private _snackBar: MatSnackBar) {
    this.determineDevice();
    this.userId = data.userId;
    this.userFirstName = data.userName;
    this.userPhone = data.userPhone;
  }

  ngOnInit() {
  }

  saveContact() {
    const userDoc = this.afs.doc('users/' + this.userId);
    // let reminder = {
    //   time: reminderTime
    // }
    userDoc.update({
      firstName: this.userFirstName,
      phone: this.userPhone
    });
    this.dialogRef.close();
    this._snackBar.open('Persoonlijke gegevens aangepast', '', {
      duration: 5000,
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  determineDevice() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      console.log('mobile device detected', navigator.userAgent);
      this.mobileDevice = true;
    } else {
      console.log('none mobile device detected');
      this.mobileDevice = false;
    }
    console.log('this.mobileDevice', this.mobileDevice);
  }
}
