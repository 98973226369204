import { Component, OnInit, Inject, Optional, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../../../profile/profile.component';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { Timestamp } from '@firebase/firestore-types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, Validators } from '@angular/forms';

import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactsComponent implements OnInit {
  contactName: string;
  contactDelay: number;
  contactPhone: string;

  numberControl: FormControl;
  phoneControl: FormControl;

  editContact: boolean;
  editContactName: string;
  editcontactDelay: number;
  editContactPhone: string;

  userId: string;

  prefix: string = environment.phone.nl;

  mobileDevice: boolean;
  constructor(public dialogRef: MatDialogRef<ContactsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public afs: AngularFirestore,
    private _snackBar: MatSnackBar) {
    this.numberControl = new FormControl('', [Validators.max(100), Validators.min(0)]);
    this.phoneControl = new FormControl('+316', [Validators.minLength(12), Validators.maxLength(12)]);
    this.determineDevice();
    this.userId = data.userId;
    if (data.contact) {
      this.contactName = data.contactName;
      this.contactDelay = data.contactDelay;
      this.contactPhone = data.contactPhone;
      console.log('data.contactPhone', data.contactPhone);
    }

    if (data.editContact) {
      this.editContact = data.editContact;
      this.editContactName = data.editContactName;
      this.editcontactDelay = data.editcontactDelay;
      this.editContactPhone = data.editContactPhone;
      console.log(this.editContactPhone);
      this.contactName = this.editContactName;
      this.contactDelay = this.editcontactDelay;
      this.contactPhone = this.editContactPhone;
      console.log(this.contactPhone);
    }

    if (!this.contactPhone) {
      this.contactPhone = this.prefix;
    }
    console.log('check user id', this.userId);
  }

  ngOnInit() {
  }

  saveContact() {
    console.log('formcontrol', this.numberControl);
    console.log('lets update this user');
    const userDoc = this.afs.doc('users/' + this.userId);
    const oldContact = {
      name: this.editContactName,
      delay: this.editcontactDelay,
      phone: this.editContactPhone
    };
    console.log('oldContact =>', oldContact);
    const contact = {
      name: this.contactName,
      delay: this.contactDelay,
      phone: this.contactPhone
    };
    console.log('Contact =>', contact);
    if (this.editContact) {
      userDoc.update({
        contacts: firebase.firestore.FieldValue.arrayRemove(oldContact)
      });
    }
    userDoc.update({
      contacts: firebase.firestore.FieldValue.arrayUnion(contact)
    });
    if (this.editContact) {
      this._snackBar.open('Contactpersoon aangepast', '', {
        duration: 5000,
      });
    } else {
      this._snackBar.open('Contactpersoon opgeslagen', '', {
        duration: 5000,
      });
    }
    console.log('Document set!');
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  determineDevice() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      console.log('mobile device detected', navigator.userAgent);
      this.mobileDevice = true;
    } else {
      console.log('none mobile device detected');
      this.mobileDevice = false;
    }
    console.log('this.mobileDevice', this.mobileDevice);
  }
}
